
import i18next from 'i18next';
import jqueryI18next from 'jquery-i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
// import i18next from 'i18next';
// import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import ptbr from '../dist/locales/pt/pt-br.json';
import en from '../dist/locales/en/en.json';
// import pt from './dist/locales/pt-br.json';
// import en from './dist/locales/en.json';

// import en from './dist/locales/en.json';
// import i18next from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';


  const lngs = {
    en: { nativeName: 'English' },
    'pt-BR': { nativeName: 'Português' }
  };
  
  const rerender = () => {
    // start localizing, details:
    // https://github.com/i18next/jquery-i18next#usage-of-selector-function
    console.log('test');
    $('body').localize();
  }
  
  $(function () {
    // use plugins and options as needed, for options, detail see
    // https://www.i18next.com
    i18next
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(i18nextBrowserLanguageDetector)
    //   .use(backend)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        debug: true,
        fallbackLng: 'pt-BR',
        resources: {
            // pt,
            // en
          'pt-BR': {
            translation: {
              menu: {
                about: 'Sobre',
                solutions: 'Soluções',
                vert: 'Verticais',
                contact: 'Contato',
                title: 'Abra ',
                title1: 'seu',
                desc: 'Mundo',
                b2title: 'Revolucione a ',
                b2title1: 'comunicação ',
                b2desc: 'com o OpenNetwork',
                b3title: 'Redes inteligentes',
                b3title1: 'para provedores',
                b3desc: 'de serviços de Internet.',
                b4title0: 'Gerencie sua',
                b4title: 'rede Wi-FI com',
                b4title1: 'alta performance e ',
                b4desc: 'independente de qual',
                b4desc1: 'marca de equipamento.',
               
              },
              contentone: {
                about: 'é o primeiro distribuidor de valor agregado fundado como puro provedor de soluções de Open Networking.',
                moreinformation: 'Saiba mais'
              },
              contenttwo: {
                about: 'Alta performance, mais segurança e flexibilidade.',
                about1: 'ISSO É OPEN NETWORKING',
                moreinformation: 'Saiba mais'
              },
              contentthree: {
                about: 'Uma plataforma, vários fornecedores.',
                about1: 'Declare a independência de sua rede WiFi .',
                moreinformation: 'Saiba mais'
              },
              contentfour:{
                title: 'Para quem fazemos?',
              },
              contentfive:{
                title: 'PLAYERS MUNDIAIS',
              }
            }
          },
          en: {
            translation: {
              menu: {
                about: 'About',
                solutions: 'Solutions',
                vert: 'Verticals',
                contact: 'Contact',
                title: 'Open ',
                title1: 'Your',
                desc: 'World',
                b2title: 'Revolutionize  ',
                b2title1: 'communication ',
                b2desc: 'with OpenNetworking',
                b3title: 'Smart networks ',
                b3title1: 'for Internet',
                b3desc: 'service providers.',
                b4title0: 'Manage your',
                b4title: 'Wi-FI network with',
                b4title1: 'high performance and ',
                b4desc: 'regardless of the',
                b4desc1: 'brand of equipment.',
              }
              ,
              contentone: {
                about: 'is the first value-added distributor founded as a pure provider of Open Networking solutions.',
                moreinformation: 'Know more'
              },
              contenttwo: {
                about: 'High performance, more security and flexibility.',
                about1: 'THIS IS OPEN NETWORKING',
                moreinformation: 'Know more'
              },
              contentthree: {
                about: 'One platform, multiple providers.',
                about1: 'Declare the independence of your WiFi network.',
                moreinformation: 'Know more'
              },
              contentfour:{
                title: 'WHO DO WE DO IT FOR?'
              },
              contentfive:{
                title: 'WORLD PLAYERS'
              }
            }
          }
        }
      }, (err, t) => {
        if (err) return console.error(err);
  
        // for options see
        // https://github.com/i18next/jquery-i18next#initialize-the-plugin
        jqueryI18next.init(i18next, $, { useOptionsAttr: true });
  
        // fill language switcher
        Object.keys(lngs).map((lng) => {
          const opt = new Option(lngs[lng].nativeName, lng);
          if (lng === i18next.resolvedLanguage) {
            opt.setAttribute("selected", "selected");
          }
          $('#languageSwitcher').append(opt);
        });
        $('#languageSwitcher').change((a, b, c) => {
          const chosenLng = $(this).find("option:selected").attr('value');
          i18next.changeLanguage(chosenLng, () => {
            rerender();
          });
        });
  
        rerender();
      });
  });